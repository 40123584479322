import React, { createContext, useMemo, useContext, useEffect } from 'react';
import { ScrollDirection, useScrollContext } from '../Providers/ScrollProvider';
import { useVisibility } from '../../hooks/useVisibility';

const TOP_START = 46;
export const AllergenenButtonContext = createContext({  
  isVisible: true
});

export const AllergenenButtonProvider = ( { children } ) => {  
    const { isVisible, show, hide } = useVisibility( true )
    const { scrollTop, direction } = useScrollContext()
    
    useEffect( () => {    
      const shouldShow = scrollTop <= TOP_START || direction !== ScrollDirection.Down    
      const shouldHide = (      
        scrollTop > TOP_START &&      
        direction === ScrollDirection.Down    
      )    
      if ( shouldShow ) {      
        show();  
      } 
      else if ( shouldHide ) {      
        hide();  
      }  
    }, [ scrollTop, direction, hide, show ] );
    
    const value = useMemo( () => ({    
      isVisible,
    }), [    
      isVisible,
    ]);
  
    return (    
      <AllergenenButtonContext.Provider value={ value }>      
        { children }    
      </AllergenenButtonContext.Provider>  
    );
  };

  export const useAllergenenButtonContext = () => useContext( AllergenenButtonContext );