import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Accordion from '../Accordion/Accordion';
import sanitizeHtml from 'sanitize-html';
import { useAllergenenButtonContext } from './AllergenenButtonProvider';

import "./Allergenen.css";

const Allergenen = () => {
  const { isVisible } = useAllergenenButtonContext()
  const sanitizer = ( html ) => {
    return sanitizeHtml( html );
  }

  const data = useStaticQuery( graphql`
    query titleAndContent {
      datoCmsAllergenenHeading {
        seoMetaTags {
          tags
        }
        alHTitel
        alHVersieDatum(formatString: "DD/MM/YYYY")
        alHBijlage {
          url
        }
        alHInfoNode {
          childMarkdownRemark {
            html
          }
        }
        alHLegendeNode {
          childMarkdownRemark {
            html
          }
        }
      }
      allDatoCmsAllergenen(sort: {fields: alTitel, order: ASC}) {
        edges {
          node {
            alTitel
            alTabelNode {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  ` );
    
    let uid=1;
    const removeSpaces = str => str.replace( /\s/g, '' );

    const markdownTitleData  = data.datoCmsAllergenenHeading;
    const tableData= data.allDatoCmsAllergenen.edges.map( ( { node: al_Data } ) => {
      let myId = removeSpaces( al_Data.alTitel ) + "_" + uid++
      return (
        <Accordion
          title={ al_Data.alTitel }
          content={ al_Data.alTabelNode.childMarkdownRemark.html }
          key={ myId }
        />
      );
    });

    const goBack = () => {
      window.history.back()
    };

    return (
        <section className="section Allergenen--Section1">
            <HelmetDatoCms seo={ markdownTitleData.seoMetaTags } />

            <div id="allergenen-div">
              <button 
                className="Button"
                id="al-button"
                onClick={ goBack }
                style = {{
                  position: 'fixed',
                  transform: `${ isVisible ? "translateY(0)" : "translateY(-100%)" }`,
                  transition: 'transform 0.5s ease-in-out',
                  overflow: 'visible',
                  marginTop: '-3em',
                  padding: '1.1rem',
                }}
              >
                Ga terug
              </button>
              <h1>{ markdownTitleData.alHTitel }</h1>
              <span id="al-markdown-info">
                <p>Versie: { markdownTitleData.alHVersieDatum }</p>
                <p>
                  Dit document kan u ook 
                  <a 
                    href={ markdownTitleData.alHBijlage.url } 
                    download 
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {` `}
                    downloaden
                  </a>.
                </p>
              </span>
              <div
                id="al-info"
                dangerouslySetInnerHTML={{ __html: sanitizer( markdownTitleData.alHInfoNode.childMarkdownRemark.html ) }}
              />
              <div
                className="legenda"
                dangerouslySetInnerHTML={{ __html: sanitizer(markdownTitleData.alHLegendeNode.childMarkdownRemark.html ) }}

              />
              <div id="allergenen-text">
                { tableData }
              </div>
            </div>
        </section>
    );
};

export default Allergenen;