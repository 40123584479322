import React from "react"
import SEO from "../components/SEO/SEO"
import Allergenen from "../components/Allergenen/Allergenen"

const AllergenenPage = () => (
  <>
    <SEO title="Allergenen" />
    <Allergenen />
  </>
)

export default AllergenenPage
